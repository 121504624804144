import React from 'react';
import './About.css';
import heroImage from '../../assets/images/SullivanRapoSeaside_600.png';
import johnImage from '../../assets/images/SullivanDesk_480.png';
import andrewImage from '../../assets/images/RapoDesk_480.png';

const About = (props: any) => {
    return (
        <div id="content">
            <div id="masthead">
                <div id="nav">
                    <a id="logo" href="http://quahogentertainment.com/"></a>
                </div>
            </div>
            <div className="about">
                <div className="aboutHero">
                    <img src={heroImage} />
                    <div className="heroCopy">
                        Quahog Corner is the latest collaboration between long-time Cape Cod residents
                        John Sullivan and Andrew Rapo.
                    </div>
                </div>
                <div className="aboutJohn">
                    <img src={johnImage} />
                    <div className="johnCopy">
                        John Sullivan was an art teacher at Barnstable High School for thirty-three years and he was the director of the award-winning Barnstable High School Drama Club. Sullivan has a masters degree in teaching from Massachusetts College of Art and studied at the California Institute for the Arts (Cal Arts). While in California he worked on the Turner Pictures animated feature, The Pagemaster.

                        
                    </div>
                    <a href="http://www.linkedin.com/pub/john-sullivan/1b/5a5/271">John's Linkedin Profile</a>
                </div>
                <div className="aboutAndrew">
                    <img src={andrewImage} />
                    <div className="andrewCopy">
                        Andrew Rapo began composing original music as a student at Sullivan’s Barnstable Summer Family Theater. Rapo spent many years developing games and interactive content at Disney Online Studios in Los Angeles. He was Executive Producer of The World of Cars Online, a game for kids based on the Pixar movie. On returning to New England in 2010, Rapo spent a brief stint on Hasbro’s Digital Marketing team as Vice President of Product Management & Platform Operations.  He now works on Conversational and Character AI systems for social robots.
                    </div>
                    <a href="http://www.linkedin.com/in/andrewrapo">Andrew's Linkedin Profile</a>
                </div>
                <div className="aboutEmmys">
                    <h1>Boston/New England Emmy Nominations</h1>
                    <h2>2015 Nominations</h2>

                    <p>
                    CHILDREN/YOUTH PROGRAMMING
                    <ul>
                    <li>The Quahog Corner Show: Episode 1, Part 1</li>
                    <li>John Sullivan, Producer/Co-creator</li>
                    <li>Andrew Rapo, Producer/Co-creator</li>
                    <li>Alex Daunais, Director Of Photography</li>
                    <li>Matthew Kohler, Assistant Director</li>
                    <li>Christopher Houghton, Associate Producer</li>
                    </ul>
                    </p>

                    
                    <p>
                    GRAPHIC ARTS SET DESIGN
                    <ul>
                    <li>Quahog Corner Episode 1 Part 1</li>
                    <li>John Sullivan, Set Designer</li>
                    </ul>
                    </p>
                    
                    <p>
                    MUSICAL COMPOSITION/ARRANGEMENT
                    <ul>
                    <li>Quahog Corner: The Magic Metaphysical Miniature Miracle Maestro Theme Song</li>
                    <li>Andrew Rapo, Composer, Producer, Performer</li>
                    </ul>
                    </p>

                    <p>
                    MUSICAL COMPOSITION/ARRANGEMENT
                    <ul>
                    <li>Main Street (Theme For Hyannis, MA)</li>
                    <li>Andrew Rapo, Composer, Producer, Performer</li>
                    </ul>
                    </p>

                    <h2>2014 Nominations</h2>

                    <p>
                    GRAPHIC ARTS PROGRAM
                    <ul>
                    <li>Quahog Corner App-Show Opening Sequence</li>
                    <li>John Sullivan, Illustrator, Animator, VFX, Director</li>
                    <li>Andrew Rapo, Editor, VFX, Composer, Producer</li>
                    <li>Quahog Entertainment, LLC</li>
                    </ul>
                    </p>
                    
                    <p>
                    MUSICAL COMPOSITION/ARRANGEMENT
                    <ul>
                    <li>Doug The Quahog Theme Song</li>
                    <li>Andrew Rapo, Composer/Lyricist/Performer</li>
                    <li>Quahog Entertainment, LLC</li>
                    </ul>
                    </p>
                    
                    <h2>2013 Nominations</h2>

                    <p>
                    GRAPHIC ARTS PROGRAM
                    <ul>
                    <li>Quahog Corner: Professor Ticonderoga Video</li>
                    <li>John Sullivan, Director/Animation/Illustration/Visual Effects/Acting</li>
                    <li>Andrew Rapo, Visual Effects/Editing/Music</li>
                    </ul>
                    </p>
                    
                    <p>
                    MUSICAL COMPOSITION/ARRANGEMENT
                    <ul>
                    <li>Quahog Corner: Professor Ticonderoga Theme Song</li>
                    <li>Andrew Rapo, Composer/Lyricist/Performer</li>
                    </ul>
                    </p>
                    
                    <p>
                    MUSICAL COMPOSITION/ARRANGEMENT
                    <ul>
                    <li>Plant Something: Song (YouTube: Link)</li>
                    <li>Andrew Rapo, Composer/Lyricist/Performer</li>
                    </ul>
                    </p>

                </div>
            </div>
        </div>
    );
}

export default About;
