import React, { Component } from 'react';

import './Content.css';

export interface ContentProps { }
export interface ContentState { }

class Content extends Component<ContentProps, ContentState> {
    constructor(props: ContentProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div id="content">
                <div id="masthead">
                    <div id="nav">
                        <a id="logo" href="http://quahogentertainment.com/"></a>
                    </div>
                </div>
                <div id="video">
                    <iframe width="960" height="540" src="https://www.youtube.com/embed/VYFhda6zrZM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
        );
    }
}

export default Content;
