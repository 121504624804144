import React, { Component } from 'react';

import './Layout.css';

export interface LayoutProps { }
export interface LayoutState { showSideDrawer: boolean }

class Layout extends Component<LayoutProps, LayoutState> {
    state = {
        showSideDrawer: false
    }

    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false });
    }

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        });
    }

    render() {
        return (
            <main id="layout">
                {this.props.children}
            </main>
        )
    }
}

export default Layout;
