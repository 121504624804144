import React from 'react';

// import Logo from '../Logo/Logo'
import classes from './Footer.module.css';
import facebookImage from '../../assets/images/icon_facebook.svg';
import youtubeImage from '../../assets/images/icon_youtube.svg';


const footer = ( props: any ) => (
    <footer className={classes.Footer}>
        <div className={classes.Social}>
            <a href="https://www.facebook.com/quahogcorner" target="_blank"><img src={facebookImage} alt="Facebook" /></a>
            <a href="https://www.youtube.com/QuahogCorner" target="_blank"><img src={youtubeImage} alt="YouTube" /></a>
        </div>
        <div >
            &copy;2013-2020 John Sullivan, Andrew Rapo
        </div>
    </footer>
);

export default footer;
