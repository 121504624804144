import React from 'react';
import classes from './Video.module.css';

const video = (props: any) => (
    <div id="content">
        <div id="masthead">
            <div id="nav">
                <a id="logo" href="http://quahogentertainment.com/"></a>
            </div>
        </div>
        <div className={classes.Video}>
            <div className={classes.VideoTitle}>Episodes</div>
            <p>Episode 1, Part 1</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/VYFhda6zrZM" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <p>Episode 1, Part 2</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/xiB08HjJ_iQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <p>Episode 2, Part 1</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/VwVuDqgZ9pY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <p>Episode 2, Part 2</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/98WAY8PPnKY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    </div>
);

export default video;
